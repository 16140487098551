"use client";
import ReactDOMServer from "react-dom/server";
//@ts-ignore
import html2pdf from "html2pdf.js";
import { IOperation } from "@umahealth/entities";
import EvaluationForm from "src/storybook/components/Imprimibles/EvaluationForm";

export const downloadOperationReport = (operation?: IOperation) => {

  const contentHtml = ReactDOMServer.renderToStaticMarkup(
		<EvaluationForm
			lugar={operation?.base?.address ?? undefined}
			nombreApellido={`${operation?.user.firstName} ${operation?.user.lastName}`}
			horaTomaServicio={
				operation?.work_day_start
					? operation?.work_day_start
					: undefined
			}
			numeroLegajo={operation?.user.nomina}
			cargoADesempeñar={operation?.user.position ?? undefined}
			firmaMedico={operation?.doctor_signature ?? undefined}
			hora={
				operation?.dt_start ? new Date(operation?.dt_start) : undefined
			}
			fecha={
				operation?.dt_start ? new Date(operation?.dt_start) : undefined
			}
			observaciones={
				operation?.questions?.resume?.observations ?? undefined
			}
			medicionesCorporales={{
				oximetria:
					operation?.questions?.health_exam?.oximetry ?? undefined,
				frecuenciaCardiaca:
					operation?.questions?.health_exam?.heart_rate ?? undefined,
			}}
			firmaEvaluador={
				operation?.questions?.resume?.evaluator_signature ?? undefined
			}
			firmaOperador={operation?.user?.signature ?? undefined}
			testRelojIntento2={
				operation?.questions?.health_exam
					?.clock_test_draw_secondAttempt ?? undefined
			}
			testRelojIntento1={
				operation?.questions?.health_exam?.clock_test_draw ?? undefined
			}
			evaluacionSalud={{
				alteracionMarcha:
					operation?.questions?.observations_exam?.gait_alteration,
				tomaMedicacion:
					operation?.questions?.observations_exam?.medicines.length &&
					operation?.questions?.observations_exam?.medicines.length >
						0
						? true
						: false,
				medicacion: {
					dosis: operation?.questions?.observations_exam?.medicines[0]
						.dose,
					fecha: operation?.questions?.observations_exam?.medicines[0]
						.last_taken_date,
					nombre: operation?.questions?.observations_exam
						?.medicines[0].medication,
				},
			}}
			drugsQuestions={operation?.questions?.substance_exam ?? undefined}
			alcoholemia={{
				primerPrueba: {
					seRealiza:
						!!operation?.questions?.substance_exam
							?.breathalyzer_result,
					etilometro:
						operation?.questions?.substance_exam?.breathalyzer
							.brand,
					NroRegistro:
						operation?.questions?.substance_exam
							?.breathalyzer_determination_number,
					NroSerie:
						operation?.questions?.substance_exam?.breathalyzer
							.serial,
				},
				segundaPrueba: {
					seRealiza:
						!!operation?.questions?.substance_exam
							?.breathalyzer_result_second_attempt,
					etilometro:
						operation?.questions?.substance_exam
							?.breathalyzer_second_attempt?.brand,
					NroRegistro:
						operation?.questions?.substance_exam
							?.breathalyzer_determination_number_second_attempt ??
						undefined,
					NroSerie:
						operation?.questions?.substance_exam
							?.breathalyzer_second_attempt?.serial,
				},
			}}
			apto={operation?.apto??undefined}
			companyId={operation?.base?.company_id}
			uid={operation?.user.uid}
		/>
  );


  var opt = {
		margin: [0, 0, 0, 0], // [top, left, bottom, right] en pulgadas o mm
		filename: operation
			? `Evaluación ${operation.id} - ${operation.user?.firstName} ${operation.user?.lastName}.pdf`
			: "Formulario evaluación.pdf",
		image: { type: "jpeg", quality: 1 },
		html2canvas: {
			dpi: 300, // Aumentar la resolución para mejor calidad
			scale: 2, // Aumentar la escala para mejor renderizado
			useCORS: true, // Si tienes imágenes externas
		},
		jsPDF: {
			precision: 32,
			floatPrecision: 32,
			format: "legal",
			unit: "mm",
			orientation: "portrait", // o 'landscape' según tu diseño
			logging: true, // Habilita el logging para depuración
			
		},
  };

  html2pdf()
    .set(opt)
    .from(contentHtml)
    .save();
};