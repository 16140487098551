// EvaluationSection.tsx
import React from "react";
import { Checkbox } from "@umahealth/occipital/client";
import { EvaluationFormProps } from "../interfaces/IEvaluationForm";

/**
 * Componente de la sección de evaluación.
 * @param {Partial<EvaluationFormProps>} props - Propiedades necesarias para la sección de evaluación.
 * @returns {JSX.Element} - Elemento JSX de la sección de evaluación.
 */
const LastQuestions: React.FC<Partial<EvaluationFormProps>> = ({
	evaluacionSalud,
	medicionesCorporales,
}) => {
	return (
		<section data-cy="Evaluación" className="w-full">
			<div className="w-full border border-grey-300 rounded-md overflow-hidden">
				<table className="w-full">
					<thead className="h-[14px] border-b border-grey-300">
						<tr className="bg-grey-100">
							<th
								className="text-grey-900 text-[10px] py-1 px-3 text-left"
								colSpan={3}
							>
								Preguntas finales
							</th>
						</tr>
					</thead>
					<tbody className="text-[11px] text-grey-600">
						<tr className="h-5">
							<td className="border-b border-r border-grey-300 p-1 pl-2 w-[266px]">
								Indicadores y tendencias de comportamiento que
								impida realizar sus tareas
							</td>
							<td className="border-b border-r border-grey-300 p-1 pl-2">
								<Checkbox
									className="checked:bg-grey-500 justify-center"
									size="size-4"
									side="right"
									variant="filled"
									checked={evaluacionSalud?.alteracionMarcha}
									disabled
								>
									Sí
								</Checkbox>
							</td>
							<td className="border-b border-grey-300 p-1 pl-2">
								<Checkbox
									className="checked:bg-grey-500 justify-center"
									size="size-4"
									side="right"
									variant="filled"
									checked={!evaluacionSalud?.alteracionMarcha}
									disabled
								>
									No
								</Checkbox>
							</td>
						</tr>
						<tr className="h-5">
							<td
								className="border-b border-grey-300 p-0.5 pl-2 text-xxs"
								colSpan={3}
							>
								<div className="text-xs text-grey-900"> Evaluación relevante/ Obervaciones </div>
								El operador tiene la baja por positivo en primer
								test de PSA y negarse a realizar el segundo
								control.
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default LastQuestions;
