// AlcoholemiaSection.tsx
import React from "react";
import { EvaluationFormProps } from "../interfaces/IEvaluationForm";

/**
 * Componente de la sección de alcoholemia.
 * @param {Partial<EvaluationFormProps>} props - Propiedades necesarias para la sección de alcoholemia.
 * @returns {JSX.Element} - Elemento JSX de la sección de alcoholemia.
 */
const DrugsSection: React.FC<Partial<EvaluationFormProps>> = ({
	drugsQuestions
}) => {
	return (
		<section data-cy="Drugs" className="w-full mt-1 mb-1">
			<div className="w-full border border-grey-300 rounded-md overflow-hidden">
				<table className="w-full">
					<thead className="h-[18px] border-b border-grey-300">
						<tr className="bg-grey-100">
							<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-left border-r border-grey-300">
								Prueba de sustancias antigenas
							</th>
							<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-left border-r border-grey-300">
								¿Se realiza?
							</th>
							<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-left border-r border-grey-300">
								Resultado
							</th>
							<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-center">
								Detalle
							</th>
						</tr>
					</thead>
					<tbody>
						<tr className="text-grey-800 text-center text-xxs">
							<td className="border-r  border-b border-grey-300 p-1 text-[9px] text-grey-600">
								Primera prueba
							</td>
							<td className="border-r border-b border-grey-300 p-1">
								{drugsQuestions?.perform_substance_test
									? "Sí"
									: "No"}
							</td>
							<td className="border-r border-b border-grey-300 p-1">
								{drugsQuestions?.substance_test_result}
							</td>
							<td className="p-1 border-b border-grey-300">
								{drugsQuestions?.positive_substances}
							</td>
						</tr>
						<tr className="text-grey-800 text-center text-xxs">
							<td className="border-r  border-b border-grey-300 p-1 text-[9px] text-grey-600">
								Segunda prueba
							</td>
							<td className="border-r border-grey-300 p-1 text-[9px] text-grey-600">
								{drugsQuestions?.perform_substance_test_second_attempt
									? "Sí"
									: "No"}
							</td>
							<td className="border-r border-grey-300 p-1">
								{
									drugsQuestions?.substance_test_result_second_attempt
								}
							</td>
							<td className="border-r border-grey-300 p-1">
								{
									drugsQuestions?.positive_substances_second_attempt
								}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default DrugsSection;
