// EvaluationSection.tsx
import React from "react";
import { Checkbox } from "@umahealth/occipital/client";
import { EvaluationFormProps } from "../interfaces/IEvaluationForm";

/**
 * Componente de la sección de evaluación.
 * @param {Partial<EvaluationFormProps>} props - Propiedades necesarias para la sección de evaluación.
 * @returns {JSX.Element} - Elemento JSX de la sección de evaluación.
 */
const EvaluationSection: React.FC<Partial<EvaluationFormProps>> = ({
	evaluacionSalud,
	medicionesCorporales,
}) => {
	return (
		<section data-cy="Evaluación" className="w-full">
			<h2 className="text-[12px] font-semibold mb-1">Evaluación</h2>
			<div className="w-full border border-grey-300 rounded-md overflow-hidden">
				<table className="w-full">
					<thead className="h-[14px] border-b border-grey-300">
						<tr className="bg-grey-100">
							<th
								className="text-grey-900 text-[10px] py-1 px-3 text-left"
								colSpan={3}
							>
								Examen de Salud. Marque y complete lo que
								corresponda.
							</th>
						</tr>
					</thead>
					<tbody className="text-[11px] text-grey-600">
						<tr className="h-5">
							<td className="border-b border-r border-grey-300 p-1 pl-2 w-[266px]">
								¿Tiene alteración de la marcha?
							</td>
							<td className="border-b border-r border-grey-300 p-1 pl-2">
								<Checkbox
									className="checked:bg-grey-500 justify-center"
									size="size-4"
									side="right"
									variant="filled"
									checked={evaluacionSalud?.alteracionMarcha}
									disabled
								>
									Sí
								</Checkbox>
							</td>
							<td className="border-b border-grey-300 p-1 pl-2">
								<Checkbox
									className="checked:bg-grey-500 justify-center"
									size="size-4"
									side="right"
									variant="filled"
									checked={!evaluacionSalud?.alteracionMarcha}
									disabled
								>
									No
								</Checkbox>
							</td>
						</tr>
						<tr className="h-5">
							<td className="border-b border-r border-grey-300 p-0.5 pl-2 ">
								¿Toma medicación?
							</td>
							<td className="border-b border-r border-grey-300 p-0.5 pl-2">
								<Checkbox
									className="checked:bg-grey-500 justify-center"
									size="size-4"
									side="right"
									variant="filled"
									checked={!!evaluacionSalud?.medicacion}
								>
									{" "}
									Sí{" "}
								</Checkbox>
							</td>
							<td className="border-b border-grey-300 p-0.5 pl-2">
								<Checkbox
									className="checked:bg-grey-500 justify-center"
									size="size-4"
									side="right"
									variant="filled"
									checked={!evaluacionSalud?.medicacion}
								>
									{" "}
									No{" "}
								</Checkbox>
							</td>
						</tr>
						<tr className="h-5">
							<td className="border-b border-r border-grey-300 p-0.5 pl-2 pb-2 ">
								Si responde sí a pregunta 2, detalle medicación,
								dosis y fecha y hora de última toma.
							</td>
							<td
								className="border-b border-grey-300 p-0.5 pl-2 pb-2"
								colSpan={2}
							>
								{evaluacionSalud?.medicacion?.nombre} |{" "}
								{evaluacionSalud?.medicacion?.dosis}{" "}
								{evaluacionSalud?.medicacion?.fecha}
							</td>
						</tr>
						<tr className="h-5">
							<td className="border-b border-r border-grey-300  p-0.5 pl-2">
								Frecuencia Cardíaca (valor normal 50-100 lpm)
							</td>
							<td className="p-0.5 pl-2 border-b border-r border-grey-300  pb-2">
								1°toma:{" "}
								<span className="text-grey-800">
									{medicionesCorporales?.oximetria}
								</span>
							</td>
							<td className="p-0.5 pl-2 border-b border-grey-300 pb-2">
								2°toma:{" "}
								<span className="text-grey-800 ">
									{
										medicionesCorporales?.segundaPruebaOximetria
									}
								</span>
							</td>
						</tr>
						<tr className="h-5">
							<td className="border-r border-grey-300 p-0.5 pl-2 pb-2">
								Oximetría
							</td>
							<td className="p-0.5 pl-2 " colSpan={2}>
								{medicionesCorporales?.oximetria}
							</td>
						</tr>
						{/* Resto de filas similares */}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default EvaluationSection;
