import React, { useState } from "react";
import styles from "../styles/operationsTable.module.css";
import Modal from "./Modal";
import { ITest } from "../../domain/ITest";
import ChartLine from "@/modules/driver/app/components/Chart";

interface TrafficLightsProps {
  testId?: number;
  testList?: ITest[];
}

export default function TrafficLights({ testId, testList }: TrafficLightsProps) {
  const [showModal, setShowModal] = useState(false);
  const test = testList?.find(test => test.id === testId) ?? null;

  if(!test) return <div className={styles.grayLight}></div>

  const reactions = test?.reaction;
  const reactionAverage = test?.reaction.reduce((acc, el) => el?.reaction_time + acc, 0) / reactions?.length;
  return (
    <>
      {reactionAverage ? (
        reactionAverage < 600 ? (
          <div className="rounded-full bg-green-500 w-4 h-4" onClick={() => setShowModal(!showModal)}></div>
        ) : reactionAverage < 750 ? (
          <div className="rounded-full bg-yellow-300 w-4 h-4" onClick={() => setShowModal(!showModal)}></div>
        ) : (
          <div className="rounded-full bg-red-500 w-4 h-4" onClick={() => setShowModal(!showModal)}></div>
        )
      ) : (
        <div className={styles.grayLight}></div>
      )}
      {testList && (
        <Modal title="Tiempos de reacción" status={showModal} setStatus={setShowModal}>
          {ChartLine(testList)}
        </Modal>
      )}
    </>
  );
}