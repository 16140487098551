'use client'
import React, { useEffect, useState } from 'react'
import { IOperationFilters } from '../../(cemex)/domain/OperationsTable';
import { generateRandomString } from '../../(cemex)/app/components/utils';
import { useFetchOperations } from '../../(cemex)/infraestructure/operationsRequets';
import AlertChecker from '../../(cemex)/app/components/AlertChecker';
import AlarmChecker from '../../(cemex)/app/components/AlarmChecker';
import Table from '@/modules/operations/app/components/table/OperationsTable'
import "@/config/compatStyles.scss";

 const filtersInitialState: IOperationFilters = {
		driver: null,
		from: null,
		to: null,
		type: 'in-progress',
 };

export default function OperationsView({ base }: { base: number }) {
	const [page, setPage] = useState<number>(1);

	const [filters, setFilters] =
		useState<IOperationFilters>(filtersInitialState);

	// State used to refresh the table: When this state changes, table will refresh
	const [updateTableUid, refreshTable] = useState<string>(() =>
		generateRandomString()
	);
	const fetchOperations = useFetchOperations({ filters, page });


	// Set current page value to 1 when any filter changes
	useEffect(() => {
		setPage(1);
	}, [filters]);

	return (
		<div className="compatAlertAI w-full h-full">
			<div className="w-full h-full">
				<AlertChecker baseId={base} />
				<AlarmChecker
					baseId={base}
					refreshTable={refreshTable}
					setPage={setPage}
					filters={filters}
					filtersInitialState={filtersInitialState}
					setFilters={setFilters}
				/>
				<div className="my-2 w-full relative">
					<Table
						operations={fetchOperations.data?.operations ?? []}
						total={fetchOperations.data?.total ?? 0}
						page={page}
						setPage={setPage}
						operationsLoading={fetchOperations.isLoading}
						filtersInitialState={filtersInitialState}
						filters={filters}
						setFilters={setFilters}
						refreshTable={refreshTable}
					/>
				</div>
			</div>
		</div>
	);
}
