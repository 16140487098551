import React, { useState } from 'react';
import XLSX from 'sheetjs-style'
import { format } from 'date-fns';
import swal from 'sweetalert';
import { errorHandler } from '@/config/errorReporting';
import { Loader } from '@/shared/index'; 
import { checkIsApt } from '@/modules/driver/app/components/utils/functions';
import { IconDownload } from '@tabler/icons-react';
import { IOperation, ISelfAssestment, ITest } from '@umahealth/entities';
import { IOperationFilters } from '@/modules/operations/(cemex)/domain/OperationsTable';
import { fetchOperations, fetchOperationsToExport } from '@/modules/operations/(cemex)/infraestructure/operationsRequets';
import Modal from '@/modules/operations/(cemex)/app/components/Modal';

interface ExportOperationsProps {
    operations: IOperation[],
    filters: IOperationFilters,
}

const ExportOperations = ({ operations, filters } : ExportOperationsProps) => {
    const [modalStatus, setModalStatus] = useState<boolean>(false);
    const [operationsLoading, setOperationsLoading] = useState<boolean>(false);

    const handleExport = async (type: 'all' | 'filtered') => {
        setOperationsLoading(true)
        try{
            let operations: IOperation[];
            if(type === 'all'){
                operations = await fetchOperationsToExport();
            }else{
                const { operations: operationsResponse } = await fetchOperations({ filters: {...filters, export: true } });
                operations = operationsResponse;
            }
            exportExcel(operations)
        } catch(error){
            swal('Ocurrió un problema', 'No pudimos exportar la lista de operaciones, por favor reinténtalo', 'warning')
            errorHandler?.report(`Error obteniendo la lista de operaciones para exportar: ${error}`);
        }
        setOperationsLoading(false)
    }

    const getCellColorByTime = (time: string) => {
        const ms = Number(time.split(' ')[0]);
        if(ms < 600){ 
            return '259B38'
        } else if(ms < 750){
            return 'C4942B';
        }else if(ms >= 750){
          return 'FF0000';
        } else {
            return '000000'
        }
    }

    const exportExcel = (data: IOperation[]) => {
        const fileName = `Operaciones_Portal.xlsx`;
        const operations = data.map((operation: IOperation) => {
            let data: Record<string, string | number> = {
                'Operador': `${operation.user?.firstName} ${operation.user?.lastName}`,
                'Iniciado': format(new Date(operation.dt_start), 'HH:mm dd/MM/yyyy'),
                'Finalizado': operation.dt_finish ? format(new Date(operation.dt_finish), 'HH:mm dd/MM/yyyy') : '-',
                '# Operación': operation.number??0,
                // 'Evaluación': selfAssessment(operation.questions),
            }
            operation?.tests?.forEach((test: ITest, index: number) => {
                const testColumnName = `Test de fatiga #${index+1}`;
                const testReactionAvg = test.reaction.reduce((acc, el) => el?.reaction_time + acc, 0) / test?.reaction.length;   
                data[testColumnName] = `${Math.ceil(testReactionAvg)} ms`;
            });
            return data;
        });        

        if (!Array.isArray(operations) || operations.length === 0) {
            throw new Error('Invalid data or data is empty.');
        }

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(operations);
      
         // Header styles
        worksheet['!rows'] = [{ hpx: 30 }];

        const headersStyles = {
            fill: {
                fgColor: { rgb: '27287F' },
            },
            font: {
                bold: true,
                color: { rgb: 'FFFFFF'}
            },
            alignment: {
                vertical: 'center',
                indent: 1,
            }
        };

        // Row styles
        const rowsStyles = {
            alignment: {
                wrapText: true
            }
        };

        // Set styles to rows
        operations.forEach((operation, rowIndex) => {
            Object.keys(operation).forEach((key, columnIndex) => {
                const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 1, c: columnIndex });

                // Apply cell styles if cell is Fatigue test
                const regexTestAvg = /^\d+\sms$/;
                let rowsStylesObj;
                if(regexTestAvg.test(worksheet[cellRef].v)){
                    rowsStylesObj = {
                        ...rowsStyles,
                        font: {
                            color: {
                                rgb: getCellColorByTime(worksheet[cellRef].v)
                            }
                        }
                    }
                }else{
                    rowsStylesObj = rowsStyles;
                }
                worksheet[cellRef].s = rowsStylesObj;
                worksheet['!cols'] = worksheet['!cols'] || [];
                worksheet['!cols'][columnIndex] = { wpx: 120 }

                // Header styles
                const headerCell = XLSX.utils.encode_cell({ r: 0, c: columnIndex });
                worksheet[headerCell].s = headersStyles;
            });
        });
        
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Operaciones');
        XLSX.writeFile(workbook, fileName);
    }

  return (
      <>
          <button title='Exportar datos' className="ml-6 flex items-center gap-3" onClick={() => setModalStatus(true)}>
              <IconDownload size={28} stroke={1} />
          </button>
          <Modal title="Exportar datos" status={modalStatus} setStatus={setModalStatus} className="w-96 h-60">
              {operationsLoading ? (
                  <div className='py-6'>
                      <Loader />
                  </div>
              ) : (
                  <div className='flex flex-col w-fit justify-center gap-4 mx-auto'>
                      <button className="flex items-center gap-3" onClick={() => exportExcel(operations)} disabled={operationsLoading}>
					  	<IconDownload size={28} stroke={1} /> Exportar página actual
                      </button>
                      <button className="flex items-center gap-3" onClick={() => handleExport('filtered')} disabled={operationsLoading}>
					  	<IconDownload size={28} stroke={1} /> Exportar todas las páginas
                      </button>
                      <button className="flex items-center gap-3" onClick={() => handleExport('all')} disabled={operationsLoading}>
					  	<IconDownload size={28} stroke={1} /> Exportar todas las operaciones
                      </button>
                  </div>
              )}
          </Modal>
      </>
  )
}

export default ExportOperations