// EvaluationData.tsx
import React from "react";
import { format } from "date-fns";
import { EvaluationFormProps } from "../interfaces/IEvaluationForm";

/**
 * Componente de datos de la evaluación.
 * @param {Partial<EvaluationFormProps>} props - Propiedades necesarias para los datos de la evaluación.
 * @returns {JSX.Element} - Elemento JSX de los datos de la evaluación.
 */
const EvaluationData: React.FC<Partial<EvaluationFormProps>> = ({
	lugar,
	fecha,
	hora,
	nombreApellido,
	horaTomaServicio,
	numeroLegajo,
	cargoADesempeñar,
}) => {
	// Formateo de fechas y horas usando date-fns
	const formattedDate = fecha ? format(fecha, "dd/MM/yyyy") : ''
	const formattedHour = hora ? format(hora, "HH:mm") : ''
	const formattedServiceHour = horaTomaServicio ?? ''

	// Descomposición de las fechas y horas formateadas
	const [day, month, year] = formattedDate.split("/");
	const [hourStr, minute] = formattedHour.split(":");
	const [hourService, minuteService] = formattedServiceHour.split(":");

	return (
		<section data-cy="Datos de la evaluación" className="w-full mb-1">
			<h2 className="text-[12px] font-semibold mb-2">
				Datos de la Evaluación
			</h2>
			<div className="w-full p-2 rounded-md border border-grey-300 bg-grey-50 text-xxs">
				<div className="flex space-x-2">
					<div className="text-grey-500 flex w-[301px]">
						Lugar:
						<span className="border-b border-grey-300 w-full text-grey-800 text-center ml-1 pb-2">
							{lugar}
						</span>
					</div>
					<div className="text-grey-500 flex">
						Fecha:
						<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
							{day}
						</span>
						/
						<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
							{month}
						</span>
						/
						<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center ml-1">
							{year}
						</span>
					</div>
					<div className="text-grey-500 flex">
						Hora:
						<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
							{hourStr}
						</span>
						:
						<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center ml-1">
							{minute}
						</span>
					</div>
				</div>
				<div className="flex space-x-2 mt-2">
					<div className="text-grey-500 flex w-[352px]">
						<span className="flex text-nowrap">
							Nombre y apellido:
						</span>
						<span className="border-b border-grey-300 w-full text-grey-800 text-center ml-1 pb-2">
							{nombreApellido}
						</span>
					</div>
					<div className="text-grey-500 flex">
						<span className="flex text-nowrap">
							Hora toma de servicio:
						</span>
						<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1 pb-2">
							{hourService}
						</span>
						:
						<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center ml-1 pb-2">
							{minuteService}
						</span>
					</div>
				</div>
				<div className="flex space-x-2 mt-2">
					<div className="text-grey-500 flex w-[352px]">
						<span className="flex text-nowrap">Función:</span>
						<span className="border-b border-grey-300 w-full text-grey-800 text-center ml-1 pb-2">
							{cargoADesempeñar}
						</span>
					</div>
					<div className="text-grey-500 flex">
						<span className="flex text-nowrap">
							Número de legajo:
						</span>
						<span className="border-b border-grey-300 w-full text-grey-800 text-center ml-1 pb-2">
							{numeroLegajo}
						</span>
					</div>
				</div>
			</div>
		</section>
	);
};

export default EvaluationData;
