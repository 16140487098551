// Header.tsx
import React from "react";
import { Image } from "@umahealth/occipital";

/**
 * Componente del encabezado del formulario.
 * @returns {JSX.Element} - Elemento JSX del encabezado.
 */
const Header: React.FC = () => {
	return (
		<section
			aria-label="Encabezado"
			className="flex justify-between items-center mb-2 w-full h-6"
		>
			<Image
				className="h-full"
				alt="Trenes Argentinos Operaciones"
				src="https://c.animaapp.com/jUK70hSM/img/layer1@2x.png"
				width={100}
				height={24}
			/>
			<h1 className="text-[11px] font-semibold leading-3 text-left text-grey-900 w-[248px] h-6">
				Protocolo de Evaluación en CRPC y de Evaluación Aleatoria de
				Personal Operativo
			</h1>
		</section>
	);
};

export default Header;
