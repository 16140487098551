// EvaluationForm.tsx
import React from "react";
import EvaluationData from "./components/EvaluationData";
import OperatorTest from "./components/OperatorTest";
import EvaluationSection from "./components/EvaluationSection";
import AlcoholemiaSection from "./components/AlcoholemiaSection";
import { EvaluationFormProps } from "./interfaces/IEvaluationForm";
import SignaturesSection from "./components/SignatureSection";
import Header from "./components/Header";
import DrugsSection from "./components/DrugsSection";
import LastQuestions from "./components/LastQuestions";
import { Image } from "@umahealth/occipital";
import { format } from "date-fns";
import { Checkbox } from "@umahealth/occipital/client";
import { QRCodeSVG } from "qrcode.react";

/**
 * Componente principal del formulario de evaluación.
 * @param {EvaluationFormProps} props - Propiedades del formulario de evaluación.
 * @returns {JSX.Element} - Elemento JSX del formulario de evaluación.
 */
export default function EvaluationForm(props: Partial<EvaluationFormProps>) {


	// Formateo de fechas y horas usando date-fns
	const formattedDate = props.fecha ? format(props.fecha, "dd/MM/yyyy") : ''
	const formattedHour = props.hora ? format(props.hora, "HH:mm") : ''

	// Descomposición de las fechas y horas formateadas
	const [day, month, year] = formattedDate.split("/");
	const [hourStr, minute] = formattedHour.split(":");


	return (
		<section aria-label="Formulario" className="p-4 w-full">
			<Header />
			<hr className="border-t border-grey-500" />
			<EvaluationData {...props} />
			<OperatorTest {...props} />
			<EvaluationSection {...props} />
			<AlcoholemiaSection {...props} />
			<DrugsSection {...props} />
			<LastQuestions {...props} />
			<SignaturesSection {...props} />
			<div className="border-t border-dashed  border-black w-full h-full py-4 flex">
				<div className="h-full border-r border-dashed border-black mr-4 pr-4">
					<section
						aria-label="Encabezado"
						className="flex justify-between items-center mb-2 w-full h-6 border-b border-solid border-grey-500 pb-2"
					>
						<Image
							className="h-full"
							alt="Trenes Argentinos Operaciones"
							src="https://c.animaapp.com/jUK70hSM/img/layer1@2x.png"
							width={134}
							height={24}
						/>
						<h1 className="text-[11px] font-semibold leading-3 text-grey-900 w-[248px] h-6 text-right">
							Talón para trabajador <br />{" "}
							<span className="text-grey-500">
								Coordinación CRPC
							</span>
						</h1>
					</section>
					<div className="flex justify-between w-full">
						<div className="text-grey-500 flex">
							Fecha:
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
								{day}
							</span>
							/
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
								{month}
							</span>
							/
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center ml-1">
								{year}
							</span>
						</div>
						<div className="text-grey-500 flex">
							Hora:
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
								{hourStr}
							</span>
							:
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center ml-1">
								{minute}
							</span>
						</div>
					</div>
					<div className="text-grey-500 flex w-[352px]">
						<span className="flex text-nowrap">
							Nombre y apellido:
						</span>
						<span className="block border-b border-grey-300 w-full text-grey-800 text-center ml-1 pb-">
							{props.nombreApellido}
						</span>
					</div>
					<div className="flex">
						<div>
							¿Tiene apto?{" "}
							<span className="text-grey-500 mr-4 text-[10px]">
								{" "}
								Marque lo que corresponda:
							</span>
						</div>
						<Checkbox
							className="checked:bg-grey-500 justify-center mr-4"
							size="size-4"
							side="right"
							variant="filled"
							checked={props.apto}
						>
							Sí
						</Checkbox>
						<Checkbox
							className="checked:bg-grey-500 justify-center"
							size="size-4"
							side="right"
							variant="filled"
							checked={!props.apto}
						>
							No
						</Checkbox>
					</div>
					<div className="grid grid-cols-3 gap-2">
						<div className="flex flex-col items-center justify-end min-h-[100px]">
							{props.firmaEvaluador && (
								<>
									<Image
										src={props.firmaEvaluador}
										alt="Firma Evaluador"
										className="mx-auto mb-2"
										width={66}
										height={30}
									/>
									<p className="font-medium">
										Firma Evaluador
									</p>
								</>
							)}
						</div>
						<div className="flex flex-col items-center justify-end min-h-[100px]">
							{props.firmaMedico && (
								<>
									<Image
										src={props.firmaMedico}
										alt="Firma Médico"
										className="mx-auto mb-2"
										width={66}
										height={30}
									/>
									<p className="font-medium">Firma Médico</p>
								</>
							)}
						</div>
						<div className="flex flex-col items-center justify-end min-h-[100px]">
							<QRCodeSVG
								className="mb-8 block"
								size={66}
								value={`https://dev.portal.alertai.umasalud.com/usuarios/${props.uid}/ultimaOperacion?companyId=${props.companyId}`}
							/>
						</div>
					</div>
				</div>
				<div className="h-full">
					<section
						aria-label="Encabezado"
						className="flex justify-between items-center mb-2 w-full h-6 border-b border-solid border-grey-500 pb-2"
					>
						<Image
							className="h-full"
							alt="Trenes Argentinos Operaciones"
							src="https://c.animaapp.com/jUK70hSM/img/layer1@2x.png"
							width={134}
							height={24}
						/>
						<h1 className="text-[11px] font-semibold leading-3 text-grey-900 w-[248px] h-6 text-right">
							Talón para Jefe supervisor <br />{" "}
							<span className="text-grey-500">
								Coordinación CRPC
							</span>
						</h1>
					</section>
					<div className="flex justify-between w-full">
						<div className="text-grey-500 flex">
							Fecha:
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
								{day}
							</span>
							/
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
								{month}
							</span>
							/
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center ml-1">
								{year}
							</span>
						</div>
						<div className="text-grey-500 flex">
							Hora:
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center mx-1">
								{hourStr}
							</span>
							:
							<span className="border-b border-grey-300 w-[27px] text-grey-800 text-center ml-1">
								{minute}
							</span>
						</div>
					</div>
					<div className="text-grey-500 flex w-[352px]">
						<span className="flex text-nowrap">
							Nombre y apellido:
						</span>
						<span className="block border-b border-grey-300 w-full text-grey-800 text-center ml-1 pb-">
							{props.nombreApellido}
						</span>
					</div>
					<div className="flex">
						<div>
							¿Tiene apto?{" "}
							<span className="text-grey-500 mr-4 text-[10px]">
								{" "}
								Marque lo que corresponda:
							</span>
						</div>
						<Checkbox
							className="checked:bg-grey-500 justify-center mr-4"
							size="size-4"
							side="right"
							variant="filled"
							checked={props.apto}
						>
							Sí
						</Checkbox>
						<Checkbox
							className="checked:bg-grey-500 justify-center"
							size="size-4"
							side="right"
							variant="filled"
							checked={!props.apto}
						>
							No
						</Checkbox>
					</div>
					<div className="grid grid-cols-3 gap-2">
						<div className="flex flex-col items-center justify-end min-h-[100px]">
							{props.firmaEvaluador && (
								<>
									<Image
										src={props.firmaEvaluador}
										alt="Firma Evaluador"
										className="mx-auto mb-2"
										width={66}
										height={30}
									/>
									<p className="font-medium">
										Firma Evaluador
									</p>
								</>
							)}
						</div>
						<div className="flex flex-col items-center justify-end min-h-[100px]">
							{props.firmaMedico && (
								<>
									<Image
										src={props.firmaMedico}
										alt="Firma Médico"
										className="mx-auto mb-2"
										width={66}
										height={30}
									/>
									<p className="font-medium">Firma Médico</p>
								</>
							)}
						</div>
						<div className="flex flex-col items-center justify-end min-h-[100px]">
							<QRCodeSVG
								className="mb-8 block"
								size={66}
								value={`https://dev.portal.alertai.umasalud.com/usuarios/${props.uid}/ultimaOperacion?companyId=${props.companyId}`}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
