// SignaturesSection.tsx
import React from "react";
import { EvaluationFormProps } from "../interfaces/IEvaluationForm";
import { Image, Paragraph } from "@umahealth/occipital";

/**
 * Componente de la sección de firmas.
 * @param {Partial<EvaluationFormProps>} props - Propiedades necesarias para la sección de firmas.
 * @returns {JSX.Element} - Elemento JSX de la sección de firmas.
 */
const SignaturesSection: React.FC<Partial<EvaluationFormProps>> = ({
	firmaOperador,
	firmaEvaluador,
	firmaMedico,
}) => {
	return (
		<section className="w-full mt-6">
			<div className="grid grid-cols-3 gap-4">
				<div className="flex flex-col items-center justify-end min-h-[100px]">
					{firmaOperador && (
						<>
							<Image
								src={firmaOperador}
								alt="Firma Operador"
								className="mx-auto mb-2 border-b border-solid border-grey-500"
								width={"66px"}
								height={"30px"}
							/>
							<Paragraph className="font-light " color='text-grey-500'>
								Firma Operador
							</Paragraph>
						</>
					)}
				</div>
				<div className="flex flex-col items-center justify-end min-h-[100px]">
					{firmaEvaluador && (
						<>
							<Image
								src={firmaEvaluador}
								alt="Firma Evaluador"
								className="mx-auto mb-2"
								width={"66px"}
								height={"30px"}
							/>
							<p className="font-medium">Firma Evaluador</p>
						</>
					)}
				</div>
				<div className="flex flex-col items-center justify-end min-h-[100px]">
					{firmaMedico && (
						<>
							<Image
								src={firmaMedico}
								alt="Firma Médico"
								className="mx-auto mb-2"
								width={"66px"}
								height={"30px"}
							/>
							<p className="font-medium">Firma Médico</p>
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export default SignaturesSection;

/**
 * <div className="grid grid-cols-3 gap-2">
						<div className="flex flex-col items-center justify-end min-h-[100px]">
							{props.firmaEvaluador && (
								<>
									<Image
										src={props.firmaEvaluador}
										alt="Firma Evaluador"
										className="mx-auto mb-2"
										width={66}
										height={30}
									/>
									<p className="font-medium">
										Firma Evaluador
									</p>
								</>
							)}
						</div>
						<div className="flex flex-col items-center justify-end min-h-[100px]">
							{props.firmaMedico && (
								<>
									<Image
										src={props.firmaMedico}
										alt="Firma Médico"
										className="mx-auto mb-2"
										width={66}
										height={30}
									/>
									<p className="font-medium">Firma Médico</p>
								</>
							)}
						</div>
						<QRCodeSVG
							className="mb-4 block"
							size={66}
							value={`https://dev.portal.alertai.umasalud.com/usuarios/${props.uid}/ultimaOperacion?companyId=${props.companyId}`}
						/>
					</div>
 */