import { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns'
import 'chart.js/auto'; // ADD THIS
import { ITest } from '@umahealth/entities';

export default function ChartLine(tests: ITest[]) {
  const ref = useRef();

  const data = {
    labels: tests.map((test: ITest) => {
      const reactionAvg = test?.reaction.reduce((acc, reactionElement) => reactionElement?.reaction_time + acc, 0) / test.reaction?.length;
      return `${format(new Date(test.dt_create), 'HH:mm')}: ${reactionAvg}ms`
    }),
    datasets: [
      {
        label: 'Promedio en milisegundos',
        data: tests.map((test: ITest) => {
          const reactionAvg = test?.reaction.reduce((acc, reactionElement) => reactionElement?.reaction_time + acc, 0) / test.reaction?.length;
          return reactionAvg
        }),
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)'
      }
    ],
  };

  return <Line ref={ref} data={data} />
};