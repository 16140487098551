// AlcoholemiaSection.tsx
import React from "react";
import { EvaluationFormProps } from "../interfaces/IEvaluationForm";

/**
 * Componente de la sección de alcoholemia.
 * @param {Partial<EvaluationFormProps>} props - Propiedades necesarias para la sección de alcoholemia.
 * @returns {JSX.Element} - Elemento JSX de la sección de alcoholemia.
 */
const AlcoholemiaSection: React.FC<Partial<EvaluationFormProps>> = ({
	alcoholemia,
}) => {
	return (
		<section data-cy="Alcoholemia" className="w-full mt-1">
			<h2 className="text-[12px] font-semibold mb-2">
				Alcoholemia (normal 0%)
			</h2>
			<div className="w-full border border-grey-300 rounded-md overflow-hidden">
				<table className="w-full">
					<thead className="h-[18px] border-b border-grey-300">
						<tr className="bg-grey-100">
							<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-left border-r border-grey-300">
								Alcoholemia
							</th>
							<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-left border-r border-grey-300">
								¿Se realiza?
							</th>
							<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-left border-r border-grey-300">
								Resultado
							</th>
							<th className="text-grey-900 text-[10px] text-nowrap font-semibold py-1 px-3 text-left  border-r border-grey-300">
								Etilómetro
							</th>
							<th className="text-grey-900 text-[10px] text-nowrap font-semibold py-1 px-3 text-left  border-r border-grey-300">
								N° Serie
							</th>
							<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-center">
								N° Registro
							</th>
						</tr>
					</thead>
					<tbody>
						<tr className="text-grey-800 text-center text-xxs">
							<td className="border-r  border-b border-grey-300 p-1 text-[9px] text-grey-600">
								Primera prueba
							</td>
							<td className="border-r border-b border-grey-300 p-1">
								{!!alcoholemia?.primerPrueba.seRealiza
									? "Sí"
									: "No"}
							</td>
							<td className="border-r border-b border-grey-300 p-1">
								{alcoholemia?.primerPrueba.resultado}
							</td>
							<td className="border-r border-b border-grey-300 p-1">
								{alcoholemia?.primerPrueba.etilometro}
							</td>
							<td className="border-r border-b border-grey-300 p-1">
								{alcoholemia?.primerPrueba.NroSerie}
							</td>
							<td className="p-1 border-b border-grey-300">
								{alcoholemia?.primerPrueba.NroRegistro}
							</td>
						</tr>
						<tr className="text-grey-800 text-center text-xxs">
							<td className="border-r border-grey-300 p-1 text-[9px] text-grey-600">
								Segunda prueba
							</td>
							<td className="border-r border-grey-300 p-1">
								{!!alcoholemia?.segundaPrueba.seRealiza
									? "Sí"
									: "No"}
							</td>
							<td className="border-r border-grey-300 p-1">
								{alcoholemia?.segundaPrueba.resultado}
							</td>
							<td className="border-r border-grey-300 p-1">
								{alcoholemia?.segundaPrueba.etilometro}
							</td>
							<td className="border-r border-grey-300 p-1">
								{alcoholemia?.segundaPrueba.NroSerie}
							</td>
							<td className="p-1">
								{alcoholemia?.segundaPrueba.NroRegistro}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default AlcoholemiaSection;
