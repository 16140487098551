import { Company, TAppModuleId } from "@/modules/company/domain/ICompany";
import { IOperation } from "@umahealth/entities";

export function generateRandomString() {
	return Math.floor(Math.random() * Date.now()).toString(36);
}

export function checkTestHasAlarm(test: IOperation["tests"][0]) {

	if (typeof test.video?.CVFatigueDetector?.human_tag === "boolean") {
		return test.video?.CVFatigueDetector?.human_tag
	}


	if (typeof test.fatigue_catboost_result != "boolean") {
		return false
	}

	if (test.fatigue_catboost_result) {
		return test.fatigue_catboost_result;
	}
}

export function checkOperationsHasAlarm(tests: IOperation["tests"]) {

	let hasAlarm : boolean = false;

	tests.forEach(test => {

		if (
			typeof test.video?.CVFatigueDetector?.human_tag ===
			"boolean"
		) {
			hasAlarm = test.video?.CVFatigueDetector?.human_tag === true  ? true : hasAlarm
		}

		if (typeof test.fatigue_catboost_result != 'boolean'){
		}
			if (test.fatigue_catboost_result) {
				hasAlarm = true;
			}
	})

	return hasAlarm
}

export function isModuleActive(company: Company, appModuleId: TAppModuleId) {
	return Boolean(
		company?.modules.find(
			(module) => module.active && module.app_module.id === appModuleId
		)
	);
}
