// OperatorTest.tsx
import React from "react";
import { Image } from "@umahealth/occipital";
import { EvaluationFormProps } from "../interfaces/IEvaluationForm";

/**
 * Componente de la prueba a completar por el operador.
 * @param {Partial<EvaluationFormProps>} props - Propiedades necesarias para la prueba del operador.
 * @returns {JSX.Element} - Elemento JSX de la prueba del operador.
 */
const OperatorTest: React.FC<Partial<EvaluationFormProps>> = ({
	testRelojIntento1,
	testRelojIntento2,
	tiempoEspacio,
}) => {
	return (
		<section
			data-cy="Prueba a completar por Operador"
			className="w-full mb-1"
		>
			<h2 className="text-[12px] font-semibold mb-2">
				Prueba a completar por Operador
			</h2>
			<div className="flex space-x-4 mt-4">
				{/* Test del Reloj Table */}
				<div className="w-[301px] border border-grey-300 rounded-md overflow-hidden">
					<table className="w-full h-full">
						<thead className="h-[18px] border-b border-grey-300">
							<tr className="bg-grey-100">
								<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-left border-r border-grey-300 w-[91px]">
									Test del Reloj
								</th>
								<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-center border-r border-grey-300 w-[110px]">
									1° intento
								</th>
								<th className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-center w-[110px]">
									2° intento
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td
									className="border-r border-grey-300 p-1 text-[9px] text-grey-600 align-top"
									rowSpan={2}
								>
									Dibuje a la derecha un reloj con manecillas
									con la hora asignada debajo:
									<div className="mt-1 text-center">
										_____ : _____
									</div>
								</td>
								<td className="border-r border-grey-300 p-2">
									{testRelojIntento1 && (
										<Image
											className="h-full w-full"
											src={testRelojIntento1}
											alt="Clock Test Draw"
											width={102}
											height={99}
										/>
									)}
								</td>
								<td className="p-2">
									{testRelojIntento2 ? 
										<Image
											src={testRelojIntento2}
											alt="Clock Test Draw"
											width={102}
											height={99}
										/> : 'no aplica'
									}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				{/* Tiempo y Espacio Table */}
				<div className="w-[252px] border border-grey-300 rounded-md overflow-hidden">
					<table className="w-full h-full">
						<thead className="h-[18px] border-b border-grey-300">
							<tr className="bg-grey-100">
								<th
									className="text-grey-900 text-[10px] font-semibold py-1 px-3 text-left"
									colSpan={2}
								>
									Tiempo y Espacio
								</th>
							</tr>
						</thead>
						<tbody className="text-[11px] text-grey-800">
							<tr className="h-6">
								<td className="border-b border-r border-grey-300 p-1 pl-2 w-[64px]">
									Día:
								</td>
								<td className="border-b border-grey-300 p-1 pl-2">
									{tiempoEspacio?.dia}
								</td>
							</tr>
							<tr className="h-6">
								<td className="border-b border-r border-grey-300 p-1 pl-2">
									Mes:
								</td>
								<td className="border-b border-grey-300 p-1 pl-2">
									{tiempoEspacio?.mes}
								</td>
							</tr>
							<tr className="h-6">
								<td className="border-b border-r border-grey-300 p-1 pl-2">
									Estación:
								</td>
								<td className="border-b border-grey-300 p-1 pl-2">
									{tiempoEspacio?.estacion}
								</td>
							</tr>
							<tr className="h-6">
								<td className="border-r border-grey-300 p-1 pl-2">
									Año:
								</td>
								<td className="p-1 pl-2">
									{tiempoEspacio?.ano}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</section>
	);
};

export default OperatorTest;
